.table {
    border-collapse: collapse;
    width: 100%;
}

.table th, .table td {
    padding: 8px 10px; /* Reduced padding for smaller row height */
    text-align: left;
    border: 1px solid #dee2e6; /* Add border to each cell */
    background-color: #ffffff; /* White background for all rows */
}

.table th {
    background-color: #f8f9fa; /* Light grey background for header */
}
